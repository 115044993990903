import React, { Component } from 'react';

interface IAgeProps {}

interface IAgeState {
  dateOfBirth: Date;
  ageOnDate: Date;
}

export default class Age extends Component<IAgeProps, IAgeState> {
  KEY = 'Age';

  constructor(props: IAgeProps) {
    super(props);

    this.setDateOfBirth = this.setDateOfBirth.bind(this);
    this.setAgeOnDate = this.setAgeOnDate.bind(this);

    // Default state
    this.state = {
      dateOfBirth: new Date(),
      ageOnDate: new Date()
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      let parsed = stateFromStorage = JSON.parse(stateFromStorage);
      parsed.dateOfBirth = new Date(parsed.dateOfBirth);
      parsed.ageOnDate = new Date(parsed.ageOnDate);
      this.state = parsed;
    }
  }

  componentDidMount() {
    const { dateOfBirth, ageOnDate } = this.state;

    if (!dateOfBirth || !ageOnDate) {
      this.reset();
    }
  }

  reset() {
    this.setState({
      dateOfBirth: new Date(),
      ageOnDate: new Date()
    });
  }

  setDateOfBirth(field: string, value: any) {
    let dateOfBirth = new Date(this.state.dateOfBirth);

    if (field == 'year') {
      dateOfBirth.setFullYear(value);
    } else if (field == 'month') {
      dateOfBirth.setMonth(value);
    } else if (field == 'day') {
      dateOfBirth.setDate(value);
    }

    this.setState({ dateOfBirth });
  }

  setAgeOnDate(field: string, value: any) {
    let ageOnDate = new Date(this.state.ageOnDate);

    if (field == 'year') {
      ageOnDate.setFullYear(value);
    } else if (field == 'month') {
      ageOnDate.setMonth(value);
    } else if (field == 'day') {
      ageOnDate.setDate(value);
    }

    this.setState({ ageOnDate });
  }

  getMonths() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  getDays() {
    let days = [];

    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }

    return days;
  }

  getYears() {
    let years = [];
    let now = new Date();
    let end = now.getFullYear();
    let start = end - 120;

    for (let i = start; i <= end; i++) {
      years.push(i);
    }

    return years.reverse();
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    const { dateOfBirth, ageOnDate } = this.state;

    if (!dateOfBirth || !ageOnDate) {
      return null;
    }

    let ageString: any = '';

    if (dateOfBirth.getTime() <= ageOnDate.getTime()) {
      let yearStart = dateOfBirth.getFullYear();
      let monthStart = dateOfBirth.getMonth();
      let dateStart = dateOfBirth.getDate();
      let yearEnd = ageOnDate.getFullYear();
      let monthEnd = ageOnDate.getMonth();
      let dateEnd = ageOnDate.getDate();
      
      let yearAge = yearEnd - yearStart;
      
      let monthAge = monthEnd - monthStart;
      if (monthStart > monthEnd) {
        yearAge--;
        monthAge = 12 + monthEnd - monthStart;
      }

      let dayAge = dateEnd - dateStart;
      if (dateStart > dateEnd) {
        monthAge--;
        dayAge = 31 + dateEnd - dateStart;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      let yearString = 'year';
      let monthString = 'month';
      let dayString = 'day';

      if (yearAge != 1) yearString += 's';
      if (monthAge != 1) monthString += 's';
      if (dayAge != 1) dayString += 's';

      let ageStringParts = [];
      
      if (yearAge > 0 || (monthAge == 0 && dayAge == 0)) ageStringParts.push(`${yearAge} ${yearString}`);
      if (monthAge > 0) ageStringParts.push(`${monthAge} ${monthString}`);
      if (dayAge > 0) ageStringParts.push(`${dayAge} ${dayString}`);

      ageString = ageStringParts.join(', ');
    } else {
      ageString = <p className="text-danger mb-0">Invalid Dates</p>;
    }

    const months = this.getMonths();
    const days = this.getDays();
    const years = this.getYears();

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Age Calculator</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="table border-bottom">
              <tbody>
                <tr>
                  <td className="align-middle unit-name"><strong>Date of Birth</strong></td>
                  <td>
                    <div className="dropdown d-inline mr-2">
                      <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                        {months[dateOfBirth.getMonth()]}
                      </button>
                      <div className="dropdown-menu">
                        {months.map((month, i) => {
                          return (
                            <button key={`dateOfBirth-${month}`} onClick={() => this.setDateOfBirth('month', i)} className="dropdown-item" type="button">{month}</button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="dropdown d-inline mr-2">
                      <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                        {dateOfBirth.getDate()}
                      </button>
                      <div className="dropdown-menu">
                        {days.map(day => {
                          return (
                            <button key={`dateOfBirth-${day}`} onClick={() => this.setDateOfBirth('day', day)} className="dropdown-item" type="button">{day}</button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="dropdown d-inline">
                      <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                        {dateOfBirth.getFullYear()}
                      </button>
                      <div className="dropdown-menu">
                        {years.map(year => {
                          return (
                            <button key={`dateOfBirth-${year}`} onClick={() => this.setDateOfBirth('year', year)} className="dropdown-item" type="button">{year}</button>
                          );
                        })}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Age on Date</strong></td>
                  <td>
                    <div className="dropdown d-inline mr-2">
                      <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                        {months[ageOnDate.getMonth()]}
                      </button>
                      <div className="dropdown-menu">
                        {months.map((month, i) => {
                          return (
                            <button key={`ageOnDate-${month}`} onClick={() => this.setAgeOnDate('month', i)} className="dropdown-item" type="button">{month}</button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="dropdown d-inline mr-2">
                      <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                        {ageOnDate.getDate()}
                      </button>
                      <div className="dropdown-menu">
                        {days.map(day => {
                          return (
                            <button key={`ageOnDate-${day}`} onClick={() => this.setAgeOnDate('day', day)} className="dropdown-item" type="button">{day}</button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="dropdown d-inline">
                      <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                        {ageOnDate.getFullYear()}
                      </button>
                      <div className="dropdown-menu">
                        {years.map(year => {
                          return (
                            <button key={`ageOnDate-${year}`} onClick={() => this.setAgeOnDate('year', year)} className="dropdown-item" type="button">{year}</button>
                          );
                        })}
                      </div>
                    </div>
                  </td>
                </tr>
                {ageString && <tr>
                  <td className="align-middle unit-name"><strong>Age</strong></td>
                  <td>{ageString}</td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}