import React, { Component, ClassAttributes } from 'react';
import IPCIDR from 'ip-cidr';

interface IIpAddressesProps {}

interface IIpAddressesState {
  addresses: IIpAddressesAddress[];
}

interface IIpAddressesAddress {
  ip: string;
  class: string;
  subnet: string;
  bits: string;
  network: string;
  first: string;
  last: string;
  broadcast: string;
  total: string;
}

export default class IpAddresses extends Component<IIpAddressesProps, IIpAddressesState> {
  KEY = 'IpAddresses';

  constructor(props: ClassAttributes<IIpAddressesProps>) {
    super(props);

    // Default state
    this.state = {
      addresses: []
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    }
  }

  reset() {
    this.setState({
      addresses: []
    });
  }

  isV4 = function(ip: string) {
    let ipv4Regex = /^(\d{1,3}\.){3,3}\d{1,3}$/;
    return ipv4Regex.test(ip);
  };

  isV6 = function(ip: string) {
    let ipv6Regex = /^(::)?(((\d{1,3}\.){3}(\d{1,3}){1})?([0-9a-f]){0,4}:{0,2}){1,8}(::)?$/i;
    return ipv6Regex.test(ip);
  };

  updateHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
    event.preventDefault();

    const dirty = event.target.value.split('\n');
    const clean: any = [];

    dirty.forEach(d => {
      let ip: string;
      let subnet: string;
      
      [ip, subnet] = d.split('/');

      const cidr = new IPCIDR(d);
      const parts: string[] = ip.split('.');
      const bits: string[] = [];

      parts.forEach(p => {
        // @ts-ignore
        bits.push(parseInt(p, 10).toString(2).padStart(8, '0'));
      });

      clean.push({
        ip,
        class: this.getClass(Number(parts[0])),
        subnet,
        bits: bits.join('.'),
        network: this.getNetwork(ip, ''),
        first: cidr.start(),
        last: cidr.end(),
        broadcast: this.getBroadcast(ip),
        total: this.getTotal(ip)
      });
    });

    this.setState({ addresses: clean });
  }
  
  getClass(digit: number) {
    if (digit >= 0 && digit <= 126) {
      return 'A';
    } else if (digit === 127) {
      return 'Loopback';
    } else if (digit >= 128 && digit <= 191) {
      return 'B';
    } else if (digit >= 192 && digit <= 223) {
      return 'C';
    } else if (digit >= 224 && digit <= 239) {
      return 'D';
    } else if (digit >= 240 && digit <= 255) {
      return 'E';
    }
  }
  
  getBinary(ip: string) {
    return ip;
  }
  
  getNetwork(ip: string, _mask: string) {
    return ip;
  }
  
  getFirst(ip: string) {
    return ip;
  }
  
  getLast(ip: string) {
    return ip;
  }
  
  getBroadcast(ip: string) {
    return ip;
  }
  
  getTotal(ip: string) {
    return ip;
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    // const { addresses } = this.state;

    return (
      <div className="container-fluid tool">
        <div className="row tool-header">
          <div className="col-md-6">
            <h1 className="tool-name">IP Addresses</h1>
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">IP address list, separated by new line (eg. 124.243.19.49/24).</label>
              <textarea className="form-control" onChange={this.updateHandler.bind(this)} rows={5}></textarea>
            </div>
          </div>
        </div>
        <div className="row tool-header">
          <div className="col-md-12">
            <h1 className="tool-name">Result</h1>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="group-nautical table border-bottom">
              <thead className="thead-light">
                <tr>
                  <th>&nbsp;</th>
                  <th>Class</th>
                  <th>CIDR</th>
                  <th>Network</th>
                  <th>First IP</th>
                  <th>Last IP</th>
                  <th>Subnet Mask</th>
                  <th>Broadcast</th>
                  <th>Total IPs</th>
                </tr>
              </thead>
              <tbody>
                {this.state.addresses.map((address, index) => {
                  return (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{address.class}</td>
                      <td>{address.ip}/{address.subnet}</td>
                      <td>{address.network}</td>
                      <td>{address.first}</td>
                      <td>{address.last}</td>
                      <td>{address.subnet}</td>
                      <td>{address.broadcast}</td>
                      <td>{address.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}