import React from 'react';
import { Link } from 'react-router-dom';

export default (
  <div className="list-group list-group-flush">
    <Link className="list-group-item list-group-item-action bg-light" to="/">Home</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/age">Age</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/basic-loan">Basic Loan</Link>
    {/* <Link className="list-group-item list-group-item-action bg-light" to="/electricity">Electricity</Link> */}
    {/* <Link className="list-group-item list-group-item-action bg-light" to="/ip-addresses">IP Addresses</Link> */}
    <Link className="list-group-item list-group-item-action bg-light" to="/data">Data</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/length">Lengths</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/number">Numbers</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/password-generator">Password Generator</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/stock-average">Stock Average</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/temperature">Temperature</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/time">Time</Link>
    <Link className="list-group-item list-group-item-action bg-light" to="/weight">Weight</Link>
  </div>
);