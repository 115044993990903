import React, { Component } from 'react';

interface IResultCardProps {
  label: string;
  value: string | number;
}

export default class ResultCard extends Component<IResultCardProps> {
  render() {
    return (
      <div className="col-md-3">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{this.props.label}</h5>
            <p className="display-4 text-success">{this.props.value}</p>
          </div>
        </div>
      </div>
    );
  }
}