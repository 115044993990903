import React from 'react';
import { Route, Switch } from 'react-router';
import Index from './Pages/Index';
import Lengths from './Tools/Lengths';
import Numbers from './Tools/Numbers';
import Weights from './Tools/Weights';
import Electricity from './Tools/Electricity';
import Temperature from './Tools/Temperature';
import Time from './Tools/Time';
import Data from './Tools/Data';
import StockAverage from './Tools/StockAverage';
import IpAddresses from './Tools/IpAddresses';
import BasicLoan from './Tools/BasicLoan';
import PasswordGenerator from './Tools/PasswordGenerator';
import Age from './Tools/Age';

export default (
  <Switch>
    <Route exact path="/" component={Index} />
    <Route path="/length" component={Lengths} />
    <Route path="/weight" component={Weights} />
    <Route path="/number" component={Numbers} />
    <Route path="/electricity" component={Electricity} />
    <Route path="/temperature" component={Temperature} />
    <Route path="/time" component={Time} />
    <Route path="/data" component={Data} />
    <Route path="/stock-average" component={StockAverage} />
    <Route path="/ip-addresses" component={IpAddresses} />
    <Route path="/basic-loan" component={BasicLoan} />
    <Route path="/password-generator" component={PasswordGenerator} />
    <Route path="/age" component={Age} />
  </Switch>
);
