import React, { Component, ClassAttributes } from 'react';
import $ from 'jquery';
import Chart from 'chart.js';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import ResultCard from './ResultCard';

interface IStockAverageProps {}

interface IStockAverageState {
  rows: any[][];
  totalVolume: number;
  averagePrice: number;
  totalFees: number;
  totalPrice: number;
  chart: IStockAverageChart;
}

interface IStockAverageChart {
  labels: string[];
  volumeData: number[];
  priceData: number[];
}

export default class StockAverage extends Component<IStockAverageProps, IStockAverageState> {
  KEY = 'StockAverage';
  chart?: Chart;

  constructor(props: ClassAttributes<IStockAverageProps>) {
    super(props);

    this.volumeHandler = this.volumeHandler.bind(this);
    this.priceHandler = this.priceHandler.bind(this);
    this.feeHandler = this.feeHandler.bind(this);

    // Default state
    this.state = {
      rows: [[uuid()], [uuid()]],
      totalVolume: 0,
      averagePrice: 0,
      totalFees: 0,
      totalPrice: 0,
      chart: {
        labels: [],
        volumeData: [],
        priceData: []
      }
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    }
  }

  componentDidMount() {
    const { rows, totalVolume, averagePrice } = this.state;

    if (!rows.length) {
      this.reset();
    } else if (Boolean(totalVolume && averagePrice)) {
      this.buildChart();
    }
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  updateChart() {
    const { labels, volumeData, priceData } = this.state.chart;

    if (!this.chart && volumeData.length && priceData.length) {
      this.buildChart();
    } else if (!this.state.rows.length) {
      this.destroyChart();
    } else if (this.chart) {
      this.chart.data.labels = labels;
      this.chart.data.datasets![1].data! = volumeData;
      this.chart.data.datasets![0].data! = priceData;

      this.chart.update({
        duration: 0
      });
    }
  }

  buildChart() {
    const { labels, volumeData, priceData } = this.state.chart;

    if (!labels.length) {
      return;
    }

    this.chart = new Chart((document.getElementById('canvas') as HTMLCanvasElement), {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            yAxisID: 'average-price-axis',
            type: 'line',
            label: 'Average Price',
            backgroundColor: '#6c757d',
            borderColor: '#6c757d',
            borderWidth: 2,
            fill: false,
            data: priceData
          },
          {
            yAxisID: 'total-volume-axis',
            type: 'bar',
            label: 'Total Volume',
            backgroundColor: '#28a745',
            borderColor: '#28a745',
            data: volumeData,
            borderWidth: 2
          }
        ]
      },
      options: {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: false
        },
        tooltips: {
          mode: 'index',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Orders'
              }
            }
          ],
          yAxes: [
            {
              type: 'linear',
              display: true,
              position: 'left',
              id: 'total-volume-axis',
              ticks: {
                min: 0
              },
              scaleLabel: {
                display: true,
                labelString: 'Total Volume'
              }
            },
            {
              type: 'linear',
              display: true,
              position: 'right',
              id: 'average-price-axis',
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                min: 0
              },
              scaleLabel: {
                display: true,
                labelString: 'Average Price'
              }
            }
          ],
        }
      }
    });
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  addRow() {
    const { rows } = this.state;

    rows.push([uuid()]);

    this.updateState(rows);
  }

  removeRow(index: number) {
    const { rows } = this.state;

    // There must always be two rows
    if (rows.length > 2) {
      rows.splice(index, 1);

      this.updateState(rows);
    }
  }

  reset() {
    this.updateState([[uuid()], [uuid()]]);
  }
  
  volumeHandler(event: any) {
    this.eventHandler(1, event);
  }
  
  priceHandler(event: any) {
    this.eventHandler(2, event);
  }
  
  feeHandler(event: any) {
    this.eventHandler(3, event);
  }

  eventHandler(field: number, event: any) {
    const value = Number(event.target.value);
    const data = $(event.target).data();
    const { rows } = this.state;

    if (value !== value) {
      return;
    }

    // @ts-ignore
    rows[data.row][field] = value;

    this.updateState(rows);
  }

  updateState(rows: any[][]) {
    let totalVolume: number = 0;
    let totalPrice: number = 0;
    let averagePrice: number = 0;
    let totalFees: number = 0;
    let labels: string[] = [];
    let volumeData: number[] = [];
    let priceData: number[] = [];

    rows.forEach((row, index) => {
      let volume = Number(row[1]);
      let price = Number(row[2]);
      let fee = Number(row[3]);

      if (volume !== volume || price !== price || volume <= 0 || price <= 0) {
        return;
      }

      labels.push('' + (index + 1));

      totalVolume += volume;
      volumeData.push(totalVolume);

      if (fee !== fee || fee <= 0) {
        fee = 0;
      }

      totalPrice += (volume * price) + fee;
      totalFees += fee;
      averagePrice = Number((totalPrice / totalVolume).toFixed(3));
      priceData.push(averagePrice);
    });

    this.setState({
      rows,
      totalVolume,
      averagePrice,
      totalFees: Number(totalFees.toFixed(2)),
      totalPrice: Number(totalPrice.toFixed(2)),
      chart: {
        labels, volumeData, priceData
      }
    });
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    const { rows, totalVolume, averagePrice, totalFees, totalPrice, chart } = this.state;
    const showRemoveButton = rows.length > 2;
    const hideGraph = !chart.volumeData.length || !chart.priceData.length;

    return (
      <div className="container-fluid tool">
        <div className="row tool-header">
          <div className="col-md-6">
            <h1 className="tool-name">Stock Average Calculator</h1>
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-success" onClick={this.addRow.bind(this)}>Add More</button>
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="table border-bottom">
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="tiny-col">&nbsp;</th>
                  <th scope="col">Volume</th>
                  <th scope="col">Price</th>
                  <th scope="col">Fee (optional)</th>
                  <th scope="col" className="tiny-col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => {
                  return (
                    <tr key={row[0]}>
                      <td className="align-middle text-right">{index + 1}.</td>
                      <td><input type="text" className="form-control" placeholder="0" defaultValue={''+(!row[1] ? '' : row[1])} onChange={this.volumeHandler} data-row={index}/></td>
                      <td><input type="text" className="form-control" placeholder="0" defaultValue={''+(!row[2] ? '' : row[2])} onChange={this.priceHandler} data-row={index}/></td>
                      <td><input type="text" className="form-control" placeholder="0" defaultValue={''+(!row[3] ? '' : row[3])} onChange={this.feeHandler} data-row={index}/></td>
                      {showRemoveButton && <td className="align-middle text-danger remove-row" onClick={() => this.removeRow(index)}>
                        <i className="fas fa-trash-alt"></i>
                      </td>}
                      {!showRemoveButton && <td>&nbsp;</td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={classnames({
          'row': true,
          'tool-body': true,
          'hide': hideGraph
        })}>
          <ResultCard label="Total Volume" value={totalVolume}/>
          <ResultCard label="Average Price" value={averagePrice}/>
          <ResultCard label="Total Fees" value={totalFees}/>
          <ResultCard label="Total Cost" value={totalPrice}/>
          <div className="col-md-12">
            <hr/>
            <canvas id="canvas"></canvas>
          </div>
        </div>
      </div>
    );
  }
}