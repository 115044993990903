import React, { Component, ClassAttributes } from 'react';
import { Decimal } from 'decimal.js';
import classnames from 'classnames';
import './styles.css';

interface IElectricityState {
  units: null | {
    [key: string]: IElectricityUnit;
  };
}

interface IElectricityUnit {
  name: string;
  symbol: string;
  factor: Decimal;
  value: Decimal;
  raw: string;
  error: boolean;
}

export default class Electricity extends Component<{}, IElectricityState> {
  KEY = 'Electricity';

  constructor(props: ClassAttributes<{}>) {
    super(props);

    this.keyUpHandler = this.keyUpHandler.bind(this);

    this.state = {
      units: null
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    }
  }

  componentDidMount() {
    if (!this.state.units) {
      this.reset();
    }
  }

  reset() {
    this.setState({
      units: {
        volt: {
          name: 'volt',
          symbol: 'V',
          factor: new Decimal(1),
          value: new Decimal(0),
          raw: '',
          error: false
        },
        ohm: {
          name: 'ohm',
          symbol: 'Ω',
          factor: new Decimal(1),
          value: new Decimal(0),
          raw: '',
          error: false
        },
        ampere: {
          name: 'ampere',
          symbol: 'A',
          factor: new Decimal(1),
          value: new Decimal(0),
          raw: '',
          error: false
        },
        coulomb: {
          name: 'coulomb',
          symbol: 'C',
          factor: new Decimal(1),
          value: new Decimal(0),
          raw: '',
          error: false
        },
        watt: {
          name: 'watt',
          symbol: 'W',
          factor: new Decimal(1),
          value: new Decimal(0),
          raw: '',
          error: false
        },
        farad: {
          name: 'farad',
          symbol: 'F',
          factor: new Decimal(1),
          value: new Decimal(0),
          raw: '',
          error: false
        },
        henry: {
          name: 'henry',
          symbol: 'H',
          factor: new Decimal(1),
          value: new Decimal(0),
          raw: '',
          error: false
        }
      }
    });
  }

  keyUpHandler(unitName: string, event: any) {
    event.preventDefault();

    let units = Object.assign({}, this.state.units);
    let raw: any = event.target.value;

    // Set the raw values
    units[unitName].raw = raw;

    try {
      const newValue = new Decimal(raw);

      // Set the base values
      units[unitName].value = newValue;
      units[unitName].raw = raw;
      units[unitName].error = false;

      if (unitName !== 'volt') {
        units.volt.value = newValue.times(units[unitName].factor);
        units.volt.raw = units['volt'].value.toFixed();
        units.volt.error = false;
      }

      // Update non base values
      Object.entries(units).forEach(([key]) => {
        if (key === 'volt' || key === unitName) {
          return;
        }

        units[key].value = units.volt.value.dividedBy(units[key].factor);
        units[key].raw = units[key].value.toFixed();
        units[key].error = false;
      });
    } catch (e) {
      units[unitName].error = true;
    }

    this.setState({ units });
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    const { units } = this.state;

    if (!units) {
      return null;
    }

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Electricity</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="group-metric table border-bottom">
              <tbody>
                {Object.entries(units).map(([key, unit]) => {
                  return (
                    <tr key={'metric_' + key}>
                      <td className="align-middle unit-name">
                        <strong>{unit.name}</strong>
                        <span className="unit-symbol">{Boolean(unit.symbol) && <em> ({unit.symbol})</em>}</span>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={classnames({
                            'form-control': true,
                            'is-invalid': unit.error
                          })}
                          placeholder="0"
                          value={unit.raw === '' ? '' : '' + unit.raw}
                          onChange={(event) => this.keyUpHandler(key, event)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}