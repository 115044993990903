import React, { Component, ClassAttributes } from 'react';
import classnames from 'classnames';
import './styles.css';

interface INumbersState {
  [key: string]: null | {
    raw: string | number;
    error: boolean;
  }
}

export default class Lengths extends Component<{}, INumbersState> {
  KEY = 'Numbers';

  constructor(props: ClassAttributes<{}>) {
    super(props);

    this.keyUpHandler = this.keyUpHandler.bind(this);

    this.state = {
      decimal: null,
      binary: null,
      hexadecimal: null,
      octal: null
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    }
  }

  componentDidMount() {
    const { decimal, binary, hexadecimal, octal } = this.state;

    if (!decimal || !binary || !hexadecimal || !octal ) {
      this.reset();
    }
  }

  reset() {
    this.setState({
      decimal: {
        raw: '',
        error: false
      },
      binary: {
        raw: '',
        error: false
      },
      hexadecimal: {
        raw: '',
        error: false
      },
      octal: {
        raw: '',
        error: false
      }
    });
  }

  keyUpHandler(unitName: string, event: any) {
    event.preventDefault();

    let decimal = this.state.decimal!;
    let binary = this.state.binary!;
    let hexadecimal = this.state.hexadecimal!;
    let octal = this.state.octal!;
    let raw: any = event.target.value;

    decimal.error = false;
    binary.error = false;
    hexadecimal.error = false;
    octal.error = false;

    if (unitName === 'decimal') {
      decimal.raw = raw;
      
      raw = Number(raw);

      // NaN check
      if (raw !== raw) {
        decimal.error = true;
      } else {
        hexadecimal.raw = (+raw).toString(16);
        octal.raw = (+raw).toString(8);
        binary.raw = (+raw).toString(2);
      }
    } else if (unitName === 'binary') {
      binary.raw = raw;
      hexadecimal.raw = parseInt(raw, 2).toString(16);
      decimal.raw = parseInt(raw, 2).toString(10);
      octal.raw = parseInt(raw, 2).toString(8);
    } else if (unitName === 'hexadecimal') {
      hexadecimal.raw = raw;
      decimal.raw = parseInt(raw, 16).toString(10);
      octal.raw = parseInt(raw, 16).toString(8);
      binary.raw = parseInt(raw, 16).toString(2);
    } else if (unitName === 'octal') {
      octal.raw = raw;
      hexadecimal.raw = parseInt(raw, 8).toString(16);
      decimal.raw = parseInt(raw, 8).toString(10);
      binary.raw = parseInt(raw, 8).toString(2);
    }

    this.setState({ decimal, binary, hexadecimal, octal });
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    const { decimal, binary, hexadecimal, octal } = this.state;

    if (!decimal || !binary || !hexadecimal || !octal) {
      return null;
    }

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Numbers</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="group-metric table border-bottom">
              <tbody>
                <tr>
                  <td className="align-middle unit-name"><strong>decimal</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': decimal.error
                      })}
                      placeholder="0"
                      value={decimal.raw === '' ? '' : '' + decimal.raw}
                      onChange={(event) => this.keyUpHandler('decimal', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>binary</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': binary.error
                      })}
                      placeholder="0"
                      value={binary.raw === '' ? '' : '' + binary.raw}
                      onChange={(event) => this.keyUpHandler('binary', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>hexadecimal</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': hexadecimal.error
                      })}
                      placeholder="0"
                      value={hexadecimal.raw === '' ? '' : '' + hexadecimal.raw}
                      onChange={(event) => this.keyUpHandler('hexadecimal', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>octal</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': octal.error
                      })}
                      placeholder="0"
                      value={octal.raw === '' ? '' : '' + octal.raw}
                      onChange={(event) => this.keyUpHandler('octal', event)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}