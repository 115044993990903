import React, { Component, ClassAttributes } from 'react';
import { Decimal } from 'decimal.js';
import classnames from 'classnames';
import './styles.css';

interface ITemperatureState {
  [key: string]: ITemperatureUnit;
}

interface ITemperatureUnit {
  name: string;
  symbol: string;
  convertTo: Function;
  convertFrom: Function;
  value: Decimal;
  raw: string;
  error: boolean;
}

const defaultState = {
  reaumur: {
    name: 'Réaumur',
    symbol: 'Ré',
    convertTo: (v: Decimal) => v.dividedBy(0.8),
    convertFrom: (v: Decimal) => v.times(0.8),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  celsius: {
    name: 'Celsius',
    symbol: 'C',
    convertTo: (v: Decimal) => v,
    convertFrom: (v: Decimal) => v,
    value: new Decimal(0),
    raw: '',
    error: false
  },
  fahrenheit: {
    name: 'Fahrenheit',
    symbol: 'F',
    convertTo: (v: Decimal) => v.minus(32).dividedBy(1.8),
    convertFrom: (v: Decimal) => v.times(1.8).plus(32),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  kelvin: {
    name: 'Kelvin',
    symbol: 'K',
    convertTo: (v: Decimal) => v.minus(273.15),
    convertFrom: (v: Decimal) => v.plus(273.15),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  rankine: {
    name: 'Rankine',
    symbol: 'Ra',
    convertTo: (v: Decimal) => v.minus(491.67).dividedBy(1.8),
    convertFrom: (v: Decimal) => v.times(1.8).plus(491.67),
    value: new Decimal(0),
    raw: '',
    error: false
  }
};

export default class Temperature extends Component<{}, ITemperatureState> {
  KEY = 'Temperature';

  constructor(props: ClassAttributes<{}>) {
    super(props);

    this.keyUpHandler = this.keyUpHandler.bind(this);

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    } else {
      this.state = Object.assign({}, defaultState);
    }
  }

  componentDidMount() {
    const { metric, imperial } = this.state;

    if (!metric || !imperial) {
      this.reset();
    }
  }

  reset() {
    this.setState(Object.assign({}, defaultState));
  }

  keyUpHandler(unitName: string, event: any) {
    event.preventDefault();

    let newState = Object.assign({}, this.state);
    let raw: any = event.target.value;

    newState[unitName].raw = raw;

    try {
      const newValue = new Decimal(raw);

      newState[unitName].value = newValue;
      newState[unitName].error = false;

      if (unitName !== 'celsius') {
        newState.celsius.value = newState[unitName].convertTo(newValue);
        newState.celsius.raw = newState.celsius.value.toFixed();
        newState.celsius.error = false;
      }

      Object.entries(newState).forEach(([key]) => {
        if (key === 'celsius' || key === unitName) {
          return;
        }

        newState[key].value = newState[key].convertFrom(newState.celsius.value);
        newState[key].raw = newState[key].value.toFixed();
        newState[key].error = false;
      });
    } catch (e) {
      newState[unitName].error = true;
    }

    this.setState(newState);
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Temperature</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="group-metric table border-bottom">
              <tbody>
                {Object.entries(this.state).map(([key, unit]) => {
                  return (
                    <tr key={key}>
                      <td className="align-middle unit-name"><strong>{unit.name}</strong></td>
                      <td>
                        <input
                          type="text"
                          className={classnames({
                            'form-control': true,
                            'is-invalid': unit.error
                          })}
                          placeholder="0"
                          value={unit.raw === '' ? '' : '' + unit.raw}
                          onChange={(event) => this.keyUpHandler(key, event)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}