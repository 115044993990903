import React, { Component, ClassAttributes } from 'react';
import { Decimal } from 'decimal.js';
import classnames from 'classnames';
import Units from './units.json';
import './styles.css';

interface ILengthsState {
  [key: string]: null | {
    anchors: {
      [key: string]: Decimal;
    };
    units: {
      [key: string]: ILengthsUnit;
    };
  };
}

interface ILengthsUnit {
  name: string;
  symbol: string;
  factor: Decimal;
  value: Decimal;
  raw: string;
  error: boolean;
}

export default class Lengths extends Component<{}, ILengthsState> {
  KEY = 'Lengths';

  constructor(props: ClassAttributes<{}>) {
    super(props);

    this.keyUpHandler = this.keyUpHandler.bind(this);

    this.state = {
      metric: null,
      imperial: null,
      nautical: null
    }

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);

      Object.entries(this.state).forEach(([groupKey, group]) => {
        Object.entries(group!.anchors).forEach(([anchorKey, anchor]) => {
          this.state[groupKey]!.anchors[anchorKey] = new Decimal(anchor);
        });

        Object.entries(group!.units).forEach(([unitKey, unit]) => {
          this.state[groupKey]!.units[unitKey].value = new Decimal(unit.value);
          this.state[groupKey]!.units[unitKey].factor = new Decimal(unit.factor);
        });
      });
    }
  }

  componentDidMount() {
    const { metric, imperial, nautical } = this.state;

    if (!metric || !imperial || !nautical) {
      this.reset();
    }
  }

  reset() {
    let newState: ILengthsState = {};

    Object.entries(Units).forEach(([groupKey, group]) => {
      newState[groupKey] = Object.assign({}, group) as any;

      Object.entries(group!.anchors).forEach(([anchorKey, anchor]) => {
        newState[groupKey]!.anchors[anchorKey] = new Decimal(anchor as any);
      });

      Object.entries(group!.units).forEach(([unitKey, unit]) => {
        newState[groupKey]!.units[unitKey].value = new Decimal(0);
        newState[groupKey]!.units[unitKey].factor = new Decimal(unit.factor);
        newState[groupKey]!.units[unitKey].raw = String('');
        newState[groupKey]!.units[unitKey].error = false;
      });
    });

    this.setState(newState);
  }

  keyUpHandler(groupName: string, unitName: string, event: any) {
    event.preventDefault();

    const metric = Object.assign({}, this.state.metric);
    const imperial = Object.assign({}, this.state.imperial);
    const nautical = Object.assign({}, this.state.nautical);
    let raw: any = event.target.value;

    try {
      const newValue = new Decimal(raw);

      // Set the base values
      if (groupName === 'metric') {
        metric.units[unitName].value = newValue;
        metric.units[unitName].raw = raw;
        metric.units[unitName].error = false;

        if (unitName !== 'metre') {
          metric.units['metre'].value = newValue.times(metric.units[unitName].factor);
          metric.units['metre'].raw = metric.units['metre'].value.toFixed();
          metric.units['metre'].error = false;
        }
      } else if (groupName === 'imperial') {
        imperial.units[unitName].value = newValue;
        imperial.units[unitName].raw = raw;
        imperial.units[unitName].error = false;

        if (unitName !== 'inch') {
          imperial.units['inch'].value = newValue.times(imperial.units[unitName].factor);
          imperial.units['inch'].raw = imperial.units['inch'].value.toFixed();
          imperial.units['inch'].error = false;
        }
      } else if (groupName === 'nautical') {
        nautical.units[unitName].value = newValue;
        nautical.units[unitName].raw = raw;
        nautical.units[unitName].error = false;

        if (unitName !== 'nmile') {
          nautical.units['nmile'].value = newValue.times(nautical.units[unitName].factor);
          nautical.units['nmile'].raw = nautical.units['nmile'].value.toFixed();
          nautical.units['nmile'].error = false;
        }
      }

      // Update other base values
      if (groupName === 'metric') {
        imperial.units['inch'].value = metric.units['metre'].value.times(metric.anchors.imperial);
        imperial.units['inch'].raw = imperial.units['inch'].value.toFixed();
        imperial.units['inch'].error = false;
        nautical.units['nmile'].value = metric.units['metre'].value.times(metric.anchors.nautical);
        nautical.units['nmile'].raw = nautical.units['nmile'].value.toFixed();
        nautical.units['nmile'].error = false;
      } else if (groupName === 'imperial') {
        metric.units['metre'].value = imperial.units['inch'].value.times(imperial.anchors.metric);
        metric.units['metre'].raw = metric.units['metre'].value.toFixed();
        metric.units['metre'].error = false;
        nautical.units['nmile'].value = imperial.units['inch'].value.times(imperial.anchors.nautical);
        nautical.units['nmile'].raw = nautical.units['nmile'].value.toFixed();
        nautical.units['nmile'].error = false;
      } else if (groupName === 'nautical') {
        metric.units['metre'].value = nautical.units['nmile'].value.times(nautical.anchors.metric);
        metric.units['metre'].raw = metric.units['metre'].value.toFixed();
        metric.units['metre'].error = false;
        imperial.units['inch'].value = nautical.units['nmile'].value.times(nautical.anchors.imperial);
        imperial.units['inch'].raw = imperial.units['inch'].value.toFixed();
        imperial.units['inch'].error = false;
      }

      // Update non base values
      Object.entries(metric.units).forEach(([key]) => {
        if (key === 'metre' || key === unitName) {
          return;
        }

        metric.units[key].value = metric.units['metre'].value.dividedBy(metric.units[key].factor);
        metric.units[key].raw = metric.units[key].value.toFixed();
        metric.units[key].error = false;
      });

      Object.entries(imperial.units).forEach(([key]) => {
        if (key === 'inch' || key === unitName) {
          return;
        }

        imperial.units[key].value = imperial.units['inch'].value.dividedBy(imperial.units[key].factor);
        imperial.units[key].raw = imperial.units[key].value.toFixed();
        imperial.units[key].error = false;
      });

      Object.entries(nautical.units).forEach(([key]) => {
        if (key === 'nmile' || key === unitName) {
          return;
        }

        nautical.units[key].value = nautical.units['nmile'].value.dividedBy(nautical.units[key].factor);
        nautical.units[key].raw = nautical.units[key].value.toFixed();
        nautical.units[key].error = false;
      });
    } catch (e) {
      console.error(e);
      // Set the raw values
      if (groupName === 'metric') {
        metric.units[unitName].error = true;
      } else if (groupName === 'imperial') {
        imperial.units[unitName].error = true;
      } else if (groupName === 'nautical') {
        nautical.units[unitName].error = true;
      }
    }

    this.setState({ metric, imperial, nautical });
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    const { metric, imperial, nautical } = this.state;

    if (!metric || !imperial || !nautical) {
      return null;
    }

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Lengths</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="group-metric table border-bottom">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" colSpan={3}>Metric</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(metric.units).map(([key, unit]) => {
                  return (
                    <tr key={'metric_' + key}>
                      <td className="align-middle unit-name">
                        <strong>{unit.name}</strong>
                        <span className="unit-symbol">{Boolean(unit.symbol) && <em> ({unit.symbol})</em>}</span>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={classnames({
                            'form-control': true,
                            'is-invalid': unit.error
                          })}
                          placeholder="0"
                          value={unit.raw === '' ? '' : '' + unit.raw}
                          onChange={(event) => this.keyUpHandler('metric', key, event)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="group-imperial table border-bottom">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" colSpan={3}>Imperial</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(imperial.units).map(([key, unit]) => {
                  return (
                    <tr key={'imperial_' + key}>
                      <td className="align-middle unit-name">
                        <strong>{unit.name}</strong>
                        <span className="unit-symbol">{Boolean(unit.symbol) && <em> ({unit.symbol})</em>}</span>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={classnames({
                            'form-control': true,
                            'is-invalid': unit.error
                          })}
                          placeholder="0"
                          value={unit.raw === '' ? '' : '' + unit.raw}
                          onChange={(event) => this.keyUpHandler('imperial', key, event)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="group-nautical table border-bottom">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" colSpan={3}>Nautical</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(nautical.units).map(([key, unit]) => {
                  return (
                    <tr key={'nautical_' + key}>
                      <td className="align-middle unit-name">
                        <strong>{unit.name}</strong>
                        <span className="unit-symbol">{Boolean(unit.symbol) && <em> ({unit.symbol})</em>}</span>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={classnames({
                            'form-control': true,
                            'is-invalid': unit.error
                          })}
                          placeholder="0"
                          value={unit.raw === '' ? '' : '' + unit.raw}
                          onChange={(event) => this.keyUpHandler('nautical', key, event)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}