import React, { Component } from 'react';
import classnames from 'classnames';
import Decimal from 'decimal.js';

interface IBasicLoanProps {}

interface IBasicLoanState {
  amount: IBasicLoanField;
  interestRate: IBasicLoanField;
  years: IBasicLoanField;
  repayments: IBasicLoanField;
  total: IBasicLoanField;
  totalInterest: IBasicLoanField;
}

interface IBasicLoanField {
  raw: string | number;
  error: boolean;
}

export default class BasicLoan extends Component<IBasicLoanProps, IBasicLoanState> {
  KEY = 'BasicLoan';

  constructor(props: IBasicLoanProps) {
    super(props);

    this.keyUpHandler = this.keyUpHandler.bind(this);

    // Default state
    this.state = {
      amount: {
        raw: '',
        error: false
      },
      interestRate: {
        raw: '',
        error: false
      },
      years: {
        raw: '',
        error: false
      },
      repayments: {
        raw: '',
        error: false
      },
      total: {
        raw: '',
        error: false
      },
      totalInterest: {
        raw: '',
        error: false
      }
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    }
  }

  componentDidMount() {
    const { amount, interestRate, years, repayments, total, totalInterest } = this.state;

    if (!amount || !interestRate || !years || !repayments || !total || !totalInterest ) {
      this.reset();
    }
  }

  reset() {
    this.setState({
      amount: {
        raw: '',
        error: false
      },
      interestRate: {
        raw: '',
        error: false
      },
      years: {
        raw: '',
        error: false
      },
      repayments: {
        raw: '',
        error: false
      },
      total: {
        raw: '',
        error: false
      },
      totalInterest: {
        raw: '',
        error: false
      }
    });
  }

  keyUpHandler(unitName: string, event: any) {
    event.preventDefault();

    let amount = this.state.amount!;
    let interestRate = this.state.interestRate!;
    let years = this.state.years!;
    let repayments = this.state.repayments!;
    let total = this.state.total!;
    let totalInterest = this.state.totalInterest!;
    let raw: any = event.target.value;

    amount.error = false;
    interestRate.error = false;
    years.error = false;
    repayments.error = false;
    total.error = false;
    totalInterest.error = false;

    if (unitName === 'amount') {
      amount.raw = raw;
    } else if (unitName === 'interestRate') {
      interestRate.raw = raw;
    } else if (unitName === 'years') {
      years.raw = raw;
    } else if (unitName === 'repayments') {
      repayments.raw = raw;
    } else if (unitName === 'total') {
      total.raw = raw;
    } else if (unitName === 'totalInterest') {
      totalInterest.raw = raw;
    }

    try {
      let principal = new Decimal(amount.raw);
      let interest = new Decimal(interestRate.raw).dividedBy(100).dividedBy(12);
      let months = new Decimal(years.raw).times(12)
      let x = interest.plus(1).pow(months);
      let payment = principal.times(x).times(interest).dividedBy(x.minus(1));

      repayments.raw = payment.toFixed(2);
      total.raw = (payment.times(months)).toFixed(2);
      totalInterest.raw = payment.times(months).minus(principal).toFixed(2);
    } catch (e) {}

    this.setState({ amount, interestRate, years, repayments, total, totalInterest });
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    const { amount, interestRate, years, repayments, total, totalInterest } = this.state;

    if (!amount || !interestRate || !years || !repayments || !total || !totalInterest) {
      return null;
    }

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Basic Loan Calculator</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="group-metric table border-bottom">
              <tbody>
                <tr>
                  <td className="align-middle unit-name"><strong>Loan Amount</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': amount!.error
                      })}
                      placeholder="0"
                      value={amount!.raw === '' ? '' : '' + amount!.raw}
                      onChange={(event) => this.keyUpHandler('amount', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Interest Rates</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': interestRate!.error
                      })}
                      placeholder="0"
                      value={interestRate!.raw === '' ? '' : '' + interestRate!.raw}
                      onChange={(event) => this.keyUpHandler('interestRate', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Length in Years</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': years!.error
                      })}
                      placeholder="0"
                      value={years!.raw === '' ? '' : '' + years!.raw}
                      onChange={(event) => this.keyUpHandler('years', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Monthly Repayments</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': repayments.error
                      })}
                      placeholder="0"
                      value={repayments.raw === '' ? '' : '' + repayments.raw}
                      onChange={(event) => this.keyUpHandler('repayments', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Total</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': total.error
                      })}
                      placeholder="0"
                      value={total.raw === '' ? '' : '' + total.raw}
                      onChange={(event) => this.keyUpHandler('total', event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Total Interest</strong></td>
                  <td>
                    <input
                      type="text"
                      className={classnames({
                        'form-control': true,
                        'is-invalid': totalInterest.error
                      })}
                      placeholder="0"
                      value={totalInterest.raw === '' ? '' : '' + totalInterest.raw}
                      onChange={(event) => this.keyUpHandler('totalInterest', event)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}