import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import App from './components/App';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles.css';

export const history = createBrowserHistory();

ReactDOM.render(
  <App history={history} />,
  document.getElementById('root')
);
