import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import './styles.css';

interface IPasswordGeneratorProps {}

interface IPasswordGeneratorState {
  selectedCharsets: number[];
  length: number;
}

export default class PasswordGenerator extends Component<IPasswordGeneratorProps, IPasswordGeneratorState> {
  KEY = 'PasswordGenerator';
  CHARSETS = [
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0123456789',
    '!?@#$%^&*',
    '(){}[]<>~+=-_\/|:;,',
  ];
  DEFAULT_LENGTH = 12;
  NUMBER_OF_PASSWORDS = 5;

  constructor(props: IPasswordGeneratorProps) {
    super(props);

    this.lengthHandler = this.lengthHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);

    // Default state
    this.state = {
      selectedCharsets: [0, 1, 2, 3, 4],
      length: this.DEFAULT_LENGTH
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    }
  }

  componentDidMount() {
    const { selectedCharsets, length } = this.state;

    if (!selectedCharsets || !length ) {
      this.reset();
    }
  }

  reset() {
    this.setState({
      selectedCharsets: [0, 1, 2, 3, 4],
      length: this.DEFAULT_LENGTH
    });
  }

  lengthHandler(event: React.ChangeEvent) {
    event.preventDefault();

    console.log((event.target as HTMLInputElement).value);
    this.setState({
      length: Number((event.target as HTMLInputElement).value)
    });
  }

  changeHandler(_event: React.ChangeEvent) {
    let selectedCharsets = [];
    let checkboxes = document.getElementsByClassName('charset');

    if (checkboxes) {
      for (let i = 0; i < checkboxes.length; i++) {
        let element = (checkboxes[i] as HTMLInputElement);

        if (element.checked) {
          selectedCharsets.push(Number(element.value));
        }
      }
    }

    this.setState({ selectedCharsets });
  }

  generatePasswords() {
    const { selectedCharsets, length } = this.state;
    const charsets = selectedCharsets.map(charset => this.CHARSETS[charset]);
    const passwords: string[] = [];

    if (!charsets.length) {
      return passwords;
    }

    for (let i = 0; i < this.NUMBER_OF_PASSWORDS; i++) {
      let password = '';

      for (let j = 0; j < length; j++) {
        let index1 = Math.floor(Math.random() * charsets.length);
        let index2 = Math.floor(Math.random() * charsets[index1].length);
        
        password += charsets[index1][index2];
      }

      passwords.push(password);
    }

    return passwords;
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    const { selectedCharsets, length } = this.state;
    const passwords = this.generatePasswords();

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Password Generator</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="table border-bottom">
              <tbody>
                <tr>
                  <td className="align-middle unit-name"><strong>Character Sets</strong></td>
                  <td>
                    {this.CHARSETS.map((charset, i) => {
                      let id = `charset_${i}`;

                      return (
                        <div key={uuid()} className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input charset"
                            id={id}
                            value={i}
                            checked={selectedCharsets.indexOf(i) > -1}
                            onChange={this.changeHandler}
                          />
                          <label className="form-check-label" htmlFor={id}>{charset}</label>
                        </div>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Length</strong></td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0"
                      value={length}
                      onChange={this.lengthHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle unit-name"><strong>Passwords</strong></td>
                  <td>
                    <ul className="list-unstyled m-0">
                      {passwords.map(p => {
                        return <li key={uuid()} className="password">{p}</li>;
                      })}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}