import React, { Component, ClassAttributes } from 'react';
import { Decimal } from 'decimal.js';
import classnames from 'classnames';
import './styles.css';

interface ITimeState {
  [key: string]: ITimeUnit;
}

interface ITimeUnit {
  name: string;
  factor: Decimal;
  value: Decimal;
  raw: string;
  error: boolean;
}

const defaultState = {
  nanosecond: {
    name: 'Nanosecond',
    factor: new Decimal('0.000000001'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  shake: {
    name: 'Shake',
    factor: new Decimal('0.00000001'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  microsecond: {
    name: 'Microsecond',
    factor: new Decimal('0.000001'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  millisecond: {
    name: 'Millisecond',
    factor: new Decimal('0.001'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  jiffy: {
    name: 'Jiffy',
    factor: new Decimal('0.0166'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  second: {
    name: 'Second',
    factor: new Decimal(1),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  microfortnight: {
    name: 'Microfortnight',
    factor: new Decimal('1.2096'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  minute: {
    name: 'Minute',
    factor: new Decimal(60),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  hour: {
    name: 'Hour',
    factor: new Decimal(3600),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  siderealDay: {
    name: 'Sidereal Day',
    factor: new Decimal('86164.0905'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  day: {
    name: 'Day',
    factor: new Decimal(86400),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  sol: {
    name: 'Sol',
    factor: new Decimal('88775.24409'),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  week: {
    name: 'Week',
    factor: new Decimal(604800),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  dogYear: {
    name: 'Dog year',
    factor: new Decimal(4492800),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  year: {
    name: 'Common Year',
    factor: new Decimal(31536000),
    value: new Decimal(0),
    raw: '',
    error: false
  },
  leapYear: {
    name: 'Leap Year',
    factor: new Decimal(31622400),
    value: new Decimal(0),
    raw: '',
    error: false
  }
};

export default class Time extends Component<{}, ITimeState> {
  KEY = 'Time';

  constructor(props: ClassAttributes<{}>) {
    super(props);

    this.keyUpHandler = this.keyUpHandler.bind(this);

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    } else {
      this.state = Object.assign({}, defaultState);
    }
  }

  componentDidMount() {
    if (!this.state) {
      this.reset();
    }
  }

  reset() {
    this.setState(Object.assign({}, defaultState));
  }

  keyUpHandler(unitName: string, event: any) {
    event.preventDefault();

    let newState = Object.assign({}, this.state);
    let raw: any = event.target.value;

    newState[unitName].raw = raw;

    try {
      const newValue = new Decimal(raw);

      newState[unitName].value = newValue;
      newState[unitName].error = false;

      if (unitName !== 'second') {
        newState.second.value = newValue.times(newState[unitName].factor);
        newState.second.raw = newState.second.value.toFixed();
        newState.second.error = false;
      }

      Object.entries(newState).forEach(([key]) => {
        if (key === 'second' || key === unitName) {
          return;
        }

        newState[key].value = newState.second.value.dividedBy(newState[key].factor);
        newState[key].raw = newState[key].value.toFixed();
        newState[key].error = false;
      });
    } catch (e) {
      newState[unitName].error = true;
    }

    this.setState(newState);
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    return (
      <div className="tool container-fluid">
        <div className="tool-header row">
          <div className="tool-title col-md-6">
            <h1 className="tool-name">Time</h1>
          </div>
          <div className="tool-buttons col-md-6 text-right">
            <button className="btn btn-danger" onClick={this.reset.bind(this)}>Reset</button>
          </div>
        </div>
        <div className="row tool-body">
          <div className="col-md-12">
            <table className="group-metric table border-bottom">
              <tbody>
                {Object.entries(this.state).map(([key, unit]) => {
                  return (
                    <tr key={key}>
                      <td className="align-middle unit-name"><strong>{unit.name}</strong></td>
                      <td>
                        <input
                          type="text"
                          className={classnames({
                            'form-control': true,
                            'is-invalid': unit.error
                          })}
                          placeholder="0"
                          value={unit.raw === '' ? '' : '' + unit.raw}
                          onChange={(event) => this.keyUpHandler(key, event)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}