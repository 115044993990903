import React, { Component } from 'react';
import { Router } from 'react-router';
import { History } from 'history';
import classnames from 'classnames';
import Routes from './Routes';
import Menu from './Menu';

interface AppProps {
  history: History
};

interface AppState {
  menuToggle: boolean
};

class App extends Component<AppProps, AppState> {
  KEY = 'App';

  constructor(props: AppProps) {
    super(props);

    // Default state
    this.state = {
      menuToggle: false
    };

    let stateFromStorage: string | null = localStorage.getItem(this.KEY);
    if (stateFromStorage) {
      this.state = JSON.parse(stateFromStorage);
    }
  }

  toggleMenu(event: any) {
    event.preventDefault();

    this.setState({
      menuToggle: !this.state.menuToggle
    });
  }

  resetLocalStorage(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    localStorage.clear();
  }

  render() {
    localStorage.setItem(this.KEY, JSON.stringify(this.state));

    return (
      <Router history={this.props.history}>
        <div id="wrapper" className={classnames({
          'd-flex': true,
          toggled: this.state.menuToggle
        })}>
          <div className="bg-light border-right" id="sidebar-wrapper">
            <div className="sidebar-heading font-weight-bold">Micro MGNT</div>
            {Menu}
          </div>
          <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
              <svg className="hide-menu" onClick={this.toggleMenu.bind(this)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
              </svg>
              <div className="navbar-heading font-weight-bold ml-2">Micro MGNT</div>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Micro MGNT
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="#">About Us</a>
                      <a className="dropdown-item" href="#">Contact</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#" onClick={this.resetLocalStorage}>Reset Local Storage</a>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
            {Routes}
          </div>
        </div>
      </Router>
    );
  }
};

export default App;
